
<template>
    <div v-if="phoneStatus!='idle'" class="btn-group mb-2 text-center" role="group" aria-label="First group" style= "display: flex;justify-content: center;align-items: center;">
        <button v-if="phoneHoldStatus=='unhold'" class="btn btn-outline-primary me-0 ms-3" @click="hold">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pause" data-v-5522efca=""><rect x="6" y="4" width="4" height="16"></rect><rect x="14" y="4" width="4" height="16"></rect></svg>
            <div class="text-center">
                &nbspHold
            </div>
        </button>
        <button v-if="phoneHoldStatus=='hold'" type="button" class="btn btn-primary " @click="unhold">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pause" data-v-5522efca="">
                <rect x="6" y="4" width="4" height="16"></rect>
                <rect x="14" y="4" width="4" height="16"></rect>
                <line x1="6" y1="4" x2="18" y2="20" stroke="currentColor" stroke-width="2" stroke-linecap="round"></line>
            </svg>
            &nbspUnhold
        </button>
        <button v-if="phoneMuteStatus=='unmute' "type="button" class="btn btn-outline-primary me-3" @click="mute">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mic-off" data-v-5522efca=""><line x1="1" y1="1" x2="23" y2="23"></line><path d="M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6"></path><path d="M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg>
            &nbsp&nbspMute
        </button>
        <button type="button" v-if="phoneMuteStatus=='mute'"  class="btn btn-primary me-3" @click="unmute">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mic" data-v-5522efca=""><path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path><path d="M19 10v2a7 7 0 0 1-14 0v-2"></path><line x1="12" y1="19" x2="12" y2="23"></line><line x1="8" y1="23" x2="16" y2="23"></line></svg>
            &nbsp&nbspUnmute
        </button>
    </div>
</template>

<script setup>
    const phoneStatus = usePhoneStatus();
    const phoneHoldStatus = usePhoneHoldStatus(); // hold, onhold
    const phoneMuteStatus = usePhoneMuteStatus(); // hold, onhold
    const dialCommand = useDialCommand(); 

    const hold = () => {
        let options = {
            directive: "hold",
        }
        dialCommand.value = options;
    }
    const unhold = () => {
        let options = {
            directive: "unhold",
        }
        dialCommand.value = options;
    }
    const mute = () => {
        let options = {
            directive: "mute",
        }
        dialCommand.value = options;
    }
    const unmute = () => {
        let options = {
            directive: "unmute",
        }
        dialCommand.value = options;
    }
    
</script>