export const usePhoneStatus = () => useState("phoneStatus", () => {
    return ref("idle");
});

export const usePhoneHoldStatus = () => useState("phoneHoldStatus", () => {
    return ref("unhold");
});

export const usePhoneMuteStatus = () => useState("phoneMuteStatus", () => {
    return ref("unmute");
});
