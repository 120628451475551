<template>
    <div>
        <div class="btn-group mt-4 mb-1 text-center " role="group" aria-label="Second group" style= "display: flex;justify-content: center;align-items: center;">
            <button type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(1)">&nbsp1</button>
                <button  type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(2)">&nbsp2</button>
                <button type="button" class="btn btn-outline-primary" @click="sendDTMF(3)">&nbsp3</button>
        </div>
        <div class="btn-group mb-1 text-center" role="group" aria-label="Second group" style= "display: flex;justify-content: center;align-items: center;">
                <button type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(4)">&nbsp4</button>
                <button  type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(5)">&nbsp5</button>
                <button type="button" class="btn btn-outline-primary" @click="sendDTMF(6)">&nbsp6</button>
        </div>
        <div class="btn-group mb-1 text-center" role="group" aria-label="Second group" style= "display: flex;justify-content: center;align-items: center;">
                <button type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(7)">&nbsp7</button>
                <button  type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(8)">&nbsp8</button>
                <button type="button" class="btn btn-outline-primary" @click="sendDTMF(9)">&nbsp9</button>
        </div>
        <div class="btn-group mb-1 text-center" role="group" aria-label="Second group" style= "display: flex;justify-content: center;align-items: center;">
                <button type="button" class="btn btn-outline-primary me-1" @click="sendDTMF('*')">&#xFE61;</button>
                <button  type="button" class="btn btn-outline-primary me-1" @click="sendDTMF(0)">&nbsp0</button>
                <button type="button" class="btn btn-outline-primary" @click="sendDTMF('#')">&#xFE5F;</button>
        </div>
    </div>
</template>

<script setup>
  const dialCommand = useDialCommand();

    const sendDTMF = (digit) => {
        let options = {
            source: "dialer",
            direction: "outbound",
            directive: "dtmf",
            digit,
        }
        
        dialCommand.value = options;
    }
</script>


